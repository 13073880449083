
import moment from 'moment'
import { computed, defineComponent, ref, watch } from 'vue';
import store from "@/store";

export default defineComponent({
  name: "DateTimeFormat",
  props: {
    date: {
      type: Number,
      required: false,
    },
    time: {type: Boolean},
    clazz: {
      required: false,
      default: ''
    }
  },

  setup(props) {
    const user = computed(() => store.getters.currentUser);
    const value = ref('');
    const buildValue = function (date, format, showTime) {
      if (date === undefined || date === '' || date === null) {
        value.value = ''
      } else {
        const finalFormat = showTime ? 'hh:mm A ' + format : format;
        value.value = moment(date).format(finalFormat);
      }
    };
    watch(user, (cb) => {
      buildValue(props.date, cb.dateFormat, props.time);
    })
    watch(() => props.time, (cb) => {
      buildValue(props.date, user.value.dateFormat, cb);
    });
    watch(() => props.date, (date) => {
      buildValue(date, user.value.dateFormat, props.time);
    });
    buildValue(props.date, user.value.dateFormat, props.time);
    return {
      value
    }
  }
})
